import React from "react"
import Layout from "../../components/layout"
import LightBox from "../../components/lightbox"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
// import useViewPort from '../../utils/useViewPort'
import { useStaticQuery, graphql } from "gatsby"
import uraaward from '../../assets/images/certs/AHAplaque.svg';
import Image from "gatsby-image"
import { Grid } from 'semantic-ui-react';




const GohLooPostPage = (params) => {
  // const { isMobile } = useViewPort();
  const data = useStaticQuery(graphql`
    query {
      clubstreet: file(relativeDirectory: {eq: "photos/club-street"}, extension: {eq: "json"}) {
        base
        childMetadataJson {
          category
          description
          housingForm
          project
          serviceType
          photos {
            caption
            alt
            orientation
            filename {
              id
              childImageSharp {
                fluid(traceSVG: {}) {
                  tracedSVG
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          titleImage {
            id
            relativePath
            childImageSharp {
              fluid(traceSVG: {}, maxWidth: 1500, maxHeight: 1000) {
                tracedSVG
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);
  const photos = data.clubstreet.childMetadataJson.photos;
  const hero = {
    fluid: data.clubstreet.childMetadataJson.titleImage.childImageSharp.fluid,
    ...photos.find(o => o.id === data.clubstreet.childMetadataJson.titleImage.id),
  };
  return (
    <Layout {...params}>
      <div className="goh-loo page">
        <SEO title={`Featured | Restoration of Goh Loo club (吾盧俱樂部)`} />
        <Banner actionText="Contact us">Restoration of Goh Loo club (吾盧俱樂部)</Banner>
        <section className="content titleimage">
          <div>
            <LightBox className="titleimage" showCaption {...hero.fluid} alt={hero.alt} caption={hero.caption} style={{ width: '100%' }}/>
          </div>
        </section>
        <section className="content post">
          <div>
            <div style={{ width: '30%', float: 'left', margin: '0 15px 0 0' }}>
              <Image fluid={{ src: uraaward }} imgStyle={{ position: 'relative' }}/>
            </div>
            <div>
              <p>For the 15 months starting from January 2015 to mid 2016, JINMAC Pte Ltd had been involved in the meticulous restoration of Goh Loo Club, which is awarded the 2016 URA Architectural Heritage award for restoration. </p>
							<p>The annual URA Architectural Heritage Awards (AHA) recognise the efforts of owners, architects, engineers, contractors and conservation specialists who have gone the extra mile to undertake high quality and sensitive restoration of national monuments and conserved buildings. </p>
							<p>Quality restoration goes beyond simply preserving a facade or the external shell of a building. It must retain the inherent spirit affirmed from research into the social and cultural history of the building. Achieving this also requires an understanding and appreciation of its original architecture, structure and materials, as well as outstanding management of the restoration project. </p>
							<p>For the past decade, the 112-year-old building languish in a state of disrepair among hip bars and restaurants. The 3-storey conservation shophouse was formerly a gentlemen’s club was once a hotbed of activity and Khoo Kok Wah, Lee Kong Chian and Lim Boo Keng.</p>
							<p>The building standing at 72 Club Street has been devotedly restored with careful considerations of its rich heritage. The restoration team took extra steps to retain its original materials, salvaging old bricks that are painstakingly pieced together to line a wall. Original green glazed balustrades, timber joists and floorboards were reused as much as possible. Old handmade glass panes were also retained, together with the basketball player-patterned metal window grilles from the 1950s – a reflection of the Club’s history as a centre to promote basketball in the Chinese community.</p>
							<h5>Restoration Team:</h5>
							<ul>
								<li>Owner: Sit Giap Company (Private) Limited</li>
								<li>Architect: Artprentice</li>
								<li>Engineer: Yan Consultant</li>
								<li>Contractor: JINMAC Pte Ltd</li>
							</ul>
							<h5>Relevant Media:</h5>
							<ul>
								<li>Goh Loo Club restoration is balance of old and new (Straits Times)</li>
								<li>URA portfolio for 72 Club Street – A Gentlemanly Revival (URA)</li>
							</ul>
							<h5>Credits:</h5>
							<ul>
								<li>Artprentice (images)</li>
							</ul>
            </div>
          </div>
        </section>
        <section className="content gallery">
          <h5>Gallery</h5>
          <Grid className="gallery" columns={3}>
            {photos.map(({ filename, caption, alt }) => {
              // const { src, srcSet } = filename.childImageSharp.fluid;
              return (
                <Grid.Column key={filename.id}>
                  <LightBox showCaption {...filename.childImageSharp.fluid} alt={alt} caption={caption} />
                </Grid.Column>
              );
            })}
          </Grid>
        </section>
      </div>
    </Layout>
  )
}

export default GohLooPostPage;